import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { selectors } from '../reducer'
import PropTypes from 'prop-types'
import { Content, LoadingIndicator } from 'components'
import { onMount, onLoad } from 'lp-utils'
import * as apiActions from 'api-actions'
import { scrollToTop, displayCurrency } from 'utils'
import { FormattedNumber } from 'react-intl'

const propTypes = {
  order: PropTypes.object.isRequired,
};

function OrderConfirmation({
  order: {
    receipt,
    purchaseDate,
    email,
    paymentMethod,
    primaryMemberId,
    donationAmount,
    membership: {
      displayName,
    },
    totalPaid,
    waiver,
    displayWaiver,
    token,
  },
}) {
  const orderIncludesDonation = donationAmount && parseFloat(donationAmount) > 0
  return (
      <Content>
        <div className="confirmation">
          <h1 className="thank-you">Thank You</h1>
          <p className="h6">Your order has been received.</p>
          <div className="confirmation-card">
            {waiver && displayWaiver && (
              <React.Fragment>
                <p className="pickup-instructions">
                  <b>Specific Card Info About Pick Up.</b> You should also
                  receive an email with instructions about obtaining your
                  physical membership card.
                </p>
                <p>
                  <b>Skip the Line.</b> Sign the required activity waiver before
                  you enter the venue.
                </p>
                <a href={waiver} target="_blank" rel="noopener noreferrer">
                  <button type="button" className="button-primary">
                    Sign Activity Waiver
                  </button>
                </a>
              </React.Fragment>
            )}
            <h1 className="h4">Order Summary</h1>
            <ul>
              <li>
                <p className="order-summary-label">Order Number</p>
                <p className="order-summary-value">{receipt}</p>
              </li>
              <li>
                <p className="order-summary-label">Purchase Date</p>
                <p className="order-summary-value">{purchaseDate}</p>
              </li>
              <li>
                <p className="order-summary-label">Purchase Total</p>
                <p className="order-summary-value">
                  <FormattedNumber
                    value={totalPaid}
                    style="currency"
                    currency="USD"
                  />
                </p>
              </li>
              {paymentMethod && (
                <li>
                  <p className="order-summary-label">Payment Method</p>
                  <p className="order-summary-value">{paymentMethod}</p>
                </li>
              )}
              <hr aria-hidden="true"/>
              <li>
                <p className="order-summary-label">Product</p>
                <p className="order-summary-value">{displayName}</p>
              </li>
              <li>
                <p className="order-summary-label">Membership ID</p>
                <p className="order-summary-value">{primaryMemberId}</p>
              </li>
              <hr aria-hidden="true"/>
              {orderIncludesDonation && (
                <React.Fragment>
                  <li>
                    <p className="order-summary-label">Donation</p>
                    <p className="order-summary-value">Donation to UOLF</p>
                  </li>
                  <li>
                    <p className="order-summary-label">Amount</p>
                    <p
                      className="order-summary-value"
                      aria-label={`Donation amount: ${displayCurrency(
                        donationAmount
                      )}`}
                    >
                      {displayCurrency(donationAmount)}
                    </p>
                  </li>
                  <li>
                    <p className="order-summary-value">Thank you!</p>
                  </li>
                </React.Fragment>
              )}
            </ul>
            <p>A copy of your purchase has been sent to {email}</p>
          </div>
        </div>
      </Content>
  )
}

function mapStateToProps(state) {
  return {
    order: selectors.order(state),
  };
}

function mapDispatchToProps(dispatch, { params: { orderId } }) {
  return {
    fetchOrder: () => {
      scrollToTop();
      dispatch(apiActions.fetchMembershipOrder(orderId));
    },
  };
}

OrderConfirmation.propTypes = propTypes;

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  onMount("fetchOrder"),
  onLoad("order.receipt", LoadingIndicator)
)(OrderConfirmation);
