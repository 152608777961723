// App-wide config (routes, env vars) goes here.

export const IS_PRODUCTION = (process.env.NODE_ENV === 'production')

//Acivity Types
export const SPORT_ACTIVITY = 'Sport'

// Memberships
export const NEW_MEMBERSHIP_ID = 'new'

export const PROTECTED_EDIT_MODE_ROUTES = [
  /\/activities\/\d+(\?.*&)?\borderToken=[^&]+/,
  /\/orders\/\d+/,
  /\/activities\/payment(\?.*(&|\\?)orderToken=[^&]+.*)?/,
  /\/orders\/confirmation\/[^/]+/
]
