import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'

const propTypes = {
 activity: Types.activity.isRequired,
 activityTime: PropTypes.string
}

const defaultProps = {
  activityTime: null
}

function ActivityHeader ({ activity, activityTime, editMode }) {
  return (
      <div>
        {
          editMode ? (
            <div className='edit-order-warning-wrapper'>
              <h1 className="h4"> Change Reservation </h1>
              <p className='warning-background'>
                Changes made to the reservation may result in a Payment Due or Refund Due. These values will be displayed on the following pages, prior to check out.
              </p>
            </div>
          ) : (
              <h1 className="h4">Choose Your Tickets</h1>
          )
        }
        <div className="ticket-type">
          <img src={ activity.thumbnail } alt="" className="activity-thumbnail" />
          <div className="ticket-info">
            <h2 className="h5">{ activity.displayName }</h2>
            { activityTime && <p>{ activityTime }</p> }
          </div>
          <hr/>
          { activity.preview && <p>{ activity.preview }</p> }
        </div>
      </div>
  )
}

ActivityHeader.propTypes = propTypes
ActivityHeader.defaultProps = defaultProps

export default ActivityHeader
