import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import * as Types from "types";
import { compose } from "recompose";
import { connect } from "react-redux";
import { Content, LoadingState, CartIcon } from "components";
import classnames from "classnames";
import { isEmpty, find, sumBy, map, flatMap } from "lodash";
import { Link } from "react-router";
import { selectors as cartSelectors } from "../reducer";
import { selectors as activitySelectors } from "../../activities/reducer";
import * as apiActions from "api-actions";
import { CartLineItem } from "../components";
import { displayCurrency, trackViewCart, navigateAwayPromptHook } from "utils";
import { selectors as orderSelectors } from "../reducer";
import { clearTicketingOrder } from "../actions";

const propTypes = {
    cart: Types.cart,
    fetchCart: PropTypes.func.isRequired,
    activities: PropTypes.arrayOf(Types.activity),
    fetchActivities: PropTypes.func.isRequired,
    deleteLineItem: PropTypes.func.isRequired
};

const defaultProps = {
    editMode: false
};

function Cart({
    cart,
    fetchCart,
    activities,
    fetchActivities,
    deleteLineItem,
    editMode,
    order,
    clearTicketingOrder,
    deleteOrder
}) {
    navigateAwayPromptHook(editMode, () => {
        deleteOrder(order)
    })

    // fetch cart and activities when page loads
    useEffect(() => {
        fetchActivities({ viewAll: true });
        // While on editMode, we get the cart from the order edit
        if (!editMode) {
            // There shouldn't be any order info if not in edit mode
            clearTicketingOrder()
            fetchCart(cart ? cart.token : null);
        }
    }, []);

    useEffect(() => {
        if (cart) {
            trackViewCart(cart);
        }
    }, [cart]);

    if (!cart || !activities) return <LoadingState />;

    const hasTaxes = cart.taxes > 0;
    let orderToken
    if (order) orderToken = order.token

    function getDisplayLabel() {
        const currentCartTotal = sumBy(
            flatMap(cart.lineItems, "lineItemTickets"),
            (lineItemTicket) => parseFloat(lineItemTicket.price) * lineItemTicket.quantity
        )
        const originalTotalPaid = sumBy(
            flatMap(order.originalOrder.cart.lineItems, "lineItemTickets"),
            (lineItemTicket) => parseFloat(lineItemTicket.price) * lineItemTicket.quantity
        )
        if (currentCartTotal === originalTotalPaid) {
            return "No payment required"
        } else if (currentCartTotal < originalTotalPaid) {
            return "Refund"
        }
        return "Payment Due"
    }

    function getTotalOrder() {
        if (!order) return hasTaxes ? cart.subtotal : cart.total
        const cartTotal = sumBy(
            flatMap(cart.lineItems, "lineItemTickets"),
            (lineItemTicket) => parseFloat(lineItemTicket.price) * lineItemTicket.quantity
        )
        const orderTotal = sumBy(
            flatMap(order.originalOrder.cart.lineItems, "lineItemTickets"),
            (lineItemTicket) => parseFloat(lineItemTicket.price) * lineItemTicket.quantity
        )
        const totalDiff = cartTotal - orderTotal
        return totalDiff
    }

    function getContinueLabel() {
        if (!(order && order.originalOrder)) return "Continue to Payment"
        const currentCartTotal = parseFloat(cart.total)
        const originalTotalPaid = parseFloat(order.originalOrder.cart.total)
        if (currentCartTotal === originalTotalPaid || currentCartTotal < originalTotalPaid) {
            return "Continue"
        }
        return "Continue to Payment"
    }

    function hasDiff() {
        if (editMode && order && order.originalOrder) {
            // if any line item has been modified related to the original order
            return cart.lineItems.some((lineItem, index) => {
                const activity = find(activities, { id: lineItem.primaryActivityId });
                const cartLineItem = find(order.originalOrder.cart.lineItems, (cartLineItem) => cartLineItem.primaryActivityId === activity.id && lineItem.bookingId === cartLineItem.bookingId)
                if (cartLineItem) {
                    // if order line item is not the same activity, it means the date, time, and quantity has changed
                    if (cartLineItem.activityId !== activity.id) {
                        return true
                    }
                    // if the order line item is the same activity but the event id is different, it means the date and time has changed
                    if (cartLineItem.eventId !== lineItem.eventId) {
                        return true
                    }
                    // if activity found, what could have changed is the quantity
                    const sumOfCurrentLineItemTickets = sumBy(lineItem.lineItemTickets, "quantity")
                    const sumOfOriginalLineItemTickets = sumBy(cartLineItem.lineItemTickets, "quantity")
                    return sumOfOriginalLineItemTickets !== sumOfCurrentLineItemTickets
                } else {
                    return false
                }
            })
        } else {
            return false
        }
    }

    function getTotalOriginalOrder() {
        const orderTotal = sumBy(
            flatMap(order.originalOrder.cart.lineItems, "lineItemTickets"),
            (lineItemTicket) => parseFloat(lineItemTicket.price) * lineItemTicket.quantity
        )
        return orderTotal
    }

    return (
        <Content>
            {
                editMode ? (
                    <h1 className="h4">Receipt #{order.receipt}</h1>
                ) : (
                    <h1 className="h4">Shopping Cart</h1>
                )
            }
            {isEmpty(cart.lineItems) ? (
                <div className="shopping-cart shopping-cart-empty">
                    <CartIcon fillColor="#A9A9A9" />
                    <p>Your Cart is Empty!</p>
                    <Link to="/activities" className="button-primary">
                        Browse Activities
                    </Link>
                </div>
            ) : (
                <div className="shopping-cart">
                    <div className="shopping-cart-header">
                        <h2 className="h4">Product Details</h2>
                    </div>
                    {cart.lineItems.map((lineItem, index) => {
                        const activity = find(activities, { id: lineItem.activityId });
                        return (
                            <CartLineItem
                                key={lineItem.id}
                                lineItem={lineItem}
                                activity={activity}
                                handleDeleteLineItem={() => {
                                    deleteLineItem(cart, lineItem);
                                }}
                                editMode={editMode}
                                cartToken={cart.token}
                                order={order}
                                showDiff
                                lineItemIndex={index}
                            />
                        );
                    })}
                    <div className="order-line-item">
                        <div className={classnames({ subtotal: hasTaxes })}>
                            {
                                editMode && hasDiff() && (
                                    <div className="previously-paid-wrapper">
                                        <p>Previously Paid</p>
                                        <p>{displayCurrency(getTotalOriginalOrder())}</p>
                                    </div>
                                )
                            }
                            <div className="ticket-info paid-section">
                                {
                                    editMode && !hasDiff() ? (
                                        <div className="previously-paid-wrapper-no-diff">
                                            <h5> Previously Paid </h5>
                                            <p>
                                                {displayCurrency(getTotalOriginalOrder())}
                                            </p>
                                        </div>
                                    ) : (
                                        <div className="payment-diff-wrapper">
                                            {hasTaxes ? <p>Subtotal</p> : editMode ? <h5> {getDisplayLabel()} </h5> : <h5>Total</h5>}
                                            <p className={classnames({ total: !hasTaxes })}>
                                                {displayCurrency(getTotalOrder())}
                                            </p>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    {hasTaxes && (
                        <React.Fragment>
                            <div className="order-line-item">
                                <div className={classnames({ taxes: hasTaxes })}>
                                    <p>Taxes</p>
                                    <div className="ticket-info">
                                        <p>{displayCurrency(cart.taxes)}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="order-line-item subtotal">
                                <div className="product-item">
                                    <h2 className="total">Total</h2>
                                </div>
                                <div className="ticket-info">
                                    <p className="total">{displayCurrency(getTotalOrder())}</p>
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                    <div className="button-three-options">
                        {
                            editMode ? hasDiff() && (
                                <Link
                                    className="button-primary"
                                    to={`/activities/payment?cartToken=${cart.token}&orderToken=${orderToken}`}
                                >
                                    {getContinueLabel()}
                                </Link>
                            ) : (
                                <div>
                                    <Link
                                        className="button-primary"
                                        to={`/activities/payment?cartToken=${cart.token}`}
                                    >
                                        {getContinueLabel()}
                                    </Link>
                                    <Link className="button-chill" to="/activities">
                                        Add Another Activity
                                    </Link>
                                </div>
                            )
                        }
                    </div>
                </div>
            )}
        </Content>
    );
}

function mapStateToProps(state) {
    return {
        order: orderSelectors.ticketingOrder(state),
        cart: cartSelectors.cart(state),
        activities: activitySelectors.activities(state)
    };
}

const mapDispatchToProps = {
    fetchCart: apiActions.fetchCart,
    fetchActivities: apiActions.fetchActivities,
    deleteLineItem: apiActions.deleteLineItem,
    clearTicketingOrder,
    deleteOrder: apiActions.deleteOrder
};

Cart.propTypes = propTypes;
Cart.defaultProps = defaultProps;

export default compose(connect(mapStateToProps, mapDispatchToProps))(Cart);
