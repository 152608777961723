import React from 'react'
import exact from 'prop-types-exact'
import { PropTypes } from 'prop-types'
import { Link } from 'react-router'

const propTypes = {
    handleAddNewSession: PropTypes.func,
    editMode: PropTypes.bool,
}

const defaultProps = {
    handleAddNewSession: null,
    editMode: false,
}

function ActivityActions({ handleAddNewSession, editMode }) {
    const orderToken = new URLSearchParams(window.location.search).get("orderToken")
    return (
        <div className="button-three-options">
            {
                editMode ? (
                    <Link to={`/orders/${orderToken}/edit`} className="button-chill add-new-session">
                        Proceed to Checkout
                    </Link>
                ) : (
                    <div>
                        {handleAddNewSession && (
                            <button type="button" onClick={() => handleAddNewSession()} className="button-chill add-new-session">Add Another Session</button>
                        )}
                        <Link to="/activities" className="button-chill">
                            Back to Activities
                        </Link>
                        <Link to="/activities/cart" className="button-chill">
                            Proceed to Checkout
                        </Link>
                    </div>
                )
            }
        </div>
    )
}

ActivityActions.propTypes = exact(propTypes)
ActivityActions.defaultProps = defaultProps

export default React.memo(ActivityActions)