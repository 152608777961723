import { useEffect, useRef } from "react";
import { browserHistory } from "react-router";
import { PROTECTED_EDIT_MODE_ROUTES } from "config";

export default function navigateAwayPromptHook(showWarning, hookCallback) {
  const navigateAwayWarningRef = useRef(true)
  const lastLocationRef = useRef(browserHistory.getCurrentLocation());
  const unBlockRef = useRef(false);
  // Prompt the user before navigating away
  useEffect(() => {
    // Handle browser tab close / page refresh
    const handleBeforeUnload = (event) => {
      if (navigateAwayWarningRef.current && showWarning) {
        event.preventDefault();
        event.returnValue = ''; // Required for Chrome to show the prompt
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    
    if(!showWarning) return

    const unlisten = browserHistory.listenBefore((location, callback) => {
      if (navigateAwayWarningRef.current && lastLocationRef.current.pathname !== location.pathname) {
        // if the query params include an order token, if pathname starts with /orders, or /activities/:id (including an id) do not show warning as these routes are included in the edit mode flow
        if (PROTECTED_EDIT_MODE_ROUTES.some((route) => route.test(`${location.pathname}${location.search}`))) {
          lastLocationRef.current = location;
          callback();
          return;
        }
        
        if (!unBlockRef.current) {
          const confirmNavigation = window.confirm('You have unsaved changes. Are you sure you want to leave?');
          if (!confirmNavigation) {
            // User clicked "Cancel", prevent navigation
            browserHistory.push(`${lastLocationRef.current.pathname}${lastLocationRef.current.search}`); // Revert to 
            unBlockRef.current = false;
            return false
          } else {
            // User clicked "OK", allow navigation
            lastLocationRef.current = location; // Allow navigation and save the new location
            // include URL parameters
            browserHistory.push(`${location.pathname}${location.search}`); // Revert to last known location
            unBlockRef.current = true;
            hookCallback()
            return true
          }
        }
      } else {
        lastLocationRef.current = location;
        callback();
      }
    });

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      unlisten();
    };
  }, []);
}