import React from "react";
import PropTypes from "prop-types";
import * as Types from "types";
import exact from "prop-types-exact";
import TicketQuantity from "./TicketQuantity";
import { flatMap, isEmpty, sum, sumBy } from "lodash";
import { Spinner } from "lp-components";

const propTypes = {
  activityTime: Types.activityTime.isRequired,
  tickets: PropTypes.arrayOf(Types.ticket),
  isTimed: PropTypes.bool.isRequired,
  lineItem: Types.lineItem,
  addTicket: PropTypes.func.isRequired,
  removeTicket: PropTypes.func.isRequired,
  maxQuantity: PropTypes.number.isRequired,
  showError: PropTypes.func.isRequired,
};

const defaultProps = {
  lineItem: null,
};

function SelectTickets({
  activityTime,
  tickets,
  isTimed,
  lineItem,
  addTicket,
  removeTicket,
  maxQuantity,
  showError,
  editMode,
  originalLineItems
}) {
  const getQuantityInCart = (ticket) => {
    if (!lineItem) return 0;

    const tickets = lineItem.lineItemTickets.filter(
      (lineItemTicket) => lineItemTicket.ticketId === ticket.id
    );
    return sum(tickets.map((t) => t.quantity));
  };

  const getTotalLineItemQuantity = () => {
    if (!lineItem) return null;
    return sumBy(lineItem.lineItemTickets, "quantity");
  };

  const getOriginalTotalLineItemQuantity = (ticket) => {
    if (!originalLineItems || !lineItem) return 0;
    const tickets = originalLineItems.lineItemTickets.filter(
      (lineItemTicket) => lineItemTicket.ticketId === ticket.id
    );
    const activityLineItemsQuantity = sumBy(
      tickets,
      'quantity'
    )
    if (!activityLineItemsQuantity) return 0;
    return activityLineItemsQuantity;
  };

  if (activityTime.soldOut)
    return <p>We're sorry, tickets for this time have sold out.</p>;
  if (!tickets) return <Spinner />;
  if (isEmpty(tickets))
    return <p>We're sorry, there are no available tickets for this time.</p>;
  return (
    <div>
      <div>
        <h3 className="h4">
          <span className="step">{isTimed ? 2 : 1}</span>
          {
            editMode ? 'Change Quantity' : 'Select Quantity'
          }
        </h3>
        {
          editMode && (
            <p className="info-background">
              To remove a ticket from your reservation, please contact customer service.
            </p>
          )
        }
      </div>
      <div className="row clearfix">
        {tickets.map((ticket) => {
          return (
            <TicketQuantity
              key={ticket.centamanId}
              maxQuantity={maxQuantity}
              totalLineItemQuantity={getTotalLineItemQuantity()}
              ticket={ticket}
              quantity={getQuantityInCart(ticket)}
              addTicket={addTicket}
              removeTicket={(ticket) => removeTicket(ticket)}
              showError={showError}
              editMode={editMode}
              originalTotalLineItemQuantity={getOriginalTotalLineItemQuantity(ticket)}
            />
          );
        })}
      </div>
    </div>
  );
}

SelectTickets.propTypes = exact(propTypes);
SelectTickets.defaultProps = defaultProps;

export default React.memo(SelectTickets);
