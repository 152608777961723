import React, { useState } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import { Modal, Button } from 'lp-components'
import { convertUdfsArrayToObj, serializeUdfs } from 'utils'
import { AttendeeDetailsForm } from '../forms'
import icon from "../../../../images/trash-bin.png"

const propTypes = {
    activity: Types.activity.isRequired,
    cart: Types.cart.isRequired,
    lineItem: Types.lineItem.isRequired,
    lineItemTicket: Types.lineItemTicket.isRequired,
    updateLineItemTicket: PropTypes.func.isRequired,
    deleteLineItemTicket: PropTypes.func.isRequired,
    openAttendeeForm: PropTypes.func.isRequired,
    closeAttendeeForm: PropTypes.func.isRequired,
    editing: PropTypes.bool.isRequired,
    heading: PropTypes.string,
    showError: PropTypes.func.isRequired
}

const defaultProps = {
    heading: 'Attendee Details'
}

function ReviewAttendeeDetails ({ 
    editing,
    heading,
    activity, 
    cart,
    lineItem,
    lineItemTicket, 
    updateLineItemTicket, 
    deleteLineItemTicket, 
    openAttendeeForm,
    closeAttendeeForm,
    showError,
    editMode,
    lineItemTicketIndex,
    order
}) {
    const [isDeleting, setIsDeleting] = useState(false)

    function disableRemoveButton() {
        // Disable button if attendee is an original attendee and if edit mode is true
        if(editMode && order && order.originalOrder) {
            // find line items for activity id
            const activityLineItems = order.originalOrder.cart.lineItems.find(li => li.activityId === activity.id)
            if(activityLineItems && activityLineItems.lineItemTickets[lineItemTicketIndex]) {
                return true
            }
            return false
        }
        return false
    }

    if (editing) {
        return (
            <Modal onClose={() => closeAttendeeForm()}>
                <AttendeeDetailsForm 
                    onCancel={closeAttendeeForm}
                    heading={heading}
                    activity={activity}
                    onSubmit={({ attendeeDetails, attendeeUdfs }) => {
                        const ticketWithUpdatedAttendee = {
                            ...lineItemTicket,
                            attendee: {
                                ...attendeeDetails,
                                ...attendeeDetails.dateOfBirth && { dateOfBirth: formatDateOfBirth(attendeeDetails.dateOfBirth) }
                            },
                            attendeeUdfs: serializeUdfs(attendeeUdfs, activity.attendeeUdfs)
                        }
                            updateLineItemTicket(cart, lineItem, ticketWithUpdatedAttendee)
                            .then(() => {
                                closeAttendeeForm()
                            })
                            .catch(err => showError(err))
                        }}
                    initialValues={{
                        attendeeDetails: lineItemTicket.attendee,
                        attendeeUdfs: convertUdfsArrayToObj(lineItemTicket.attendeeUdfs)
                    }} 
                />
            </Modal>
        )
    } else {
        return (
            <div className="attendee-details">
                <div className="details">
                    <span className="detail-header">{lineItemTicket.attendee.firstName + ' ' + lineItemTicket.attendee.lastName}</span>
                </div>
                <div className="attendee-edit">
                    <button 
                        onClick={() => openAttendeeForm(lineItemTicket.id)}
                        className="edit"
                        aria-label={`Edit ${lineItemTicket.attendee.firstName + ' ' + lineItemTicket.attendee.lastName}`}
                    >
                        Edit
                    </button>
                    <Button
                        onClick={() => {
                            if(disableRemoveButton()) {
                                return
                            }
                            setIsDeleting(true)
                            return deleteLineItemTicket(lineItemTicket)
                                .catch(() => setIsDeleting(false))
                        }}
                        className="remove"
                        style="secondary"
                        aria-label={`Remove ${lineItemTicket.attendee.firstName + ' ' + lineItemTicket.attendee.lastName}`}
                        submitting={isDeleting}
                        disabled={disableRemoveButton()}
                    >
                        <img src={icon} />
                    </Button>
                </div>
            </div>
        )
    }

}

const formatDateOfBirth = (dob) => {
    if (typeof(dob) === 'string') return dob
    return dob.format('YYYY-MM-DD')
}


ReviewAttendeeDetails.propTypes = exact(propTypes)
ReviewAttendeeDetails.defaultProps = defaultProps

export default React.memo(ReviewAttendeeDetails)