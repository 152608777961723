import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Calendar } from "react-calendar";
import { compose } from "recompose";
import { connect } from "react-redux";
import { selectors as activitySelectors } from "../../reducer";
import { formValueSelector } from "redux-form";
import { isEmpty, last } from "lodash";
import { toDateString } from "utils/date-formatting-utils";

const propTypes = {
  activityLineItem: PropTypes.object,
  change: PropTypes.func.isRequired,
  filteredOrderedActivityTimes: PropTypes.object,
  orderedActivityTimes: PropTypes.object,
};
const defaultProps = {};

function BookingCalendar({
  activityLineItem,
  change,
  filteredOrderedActivityTimes,
  orderedActivityTimes,
}) {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [highlightedDate, setHighlightedDate] = useState(new Date());
  selectedDate.setHours(0, 0, 0, 0); // Set time to midnight to ignore time

  const dateToString = toDateString(selectedDate);
  const activityDates = Object.keys(orderedActivityTimes).sort((a, b) => new Date(a) - new Date(b));
  const { soldOutDates, filteredActivityTimes } = filteredOrderedActivityTimes;

// Set 'active' date on Calendar
if (!activityLineItem) {
  if ((!isEmpty(soldOutDates) && soldOutDates.includes(dateToString)) || (!isEmpty(activityDates) && !activityDates.includes(dateToString))) {
    let counter = 1;
    let nextDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate());

    nextDate.setDate(nextDate.getDate() + counter);
    while (soldOutDates.includes(toDateString(nextDate)) || !activityDates.includes(toDateString(nextDate))) {
      counter++;
      nextDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate());
      nextDate.setDate(nextDate.getDate() + counter);
    }
 
    setSelectedDate(nextDate);
  }
} else if (dateToString !== activityLineItem.startDate) {
  const startDateObject = new Date(activityLineItem.startDatetime);
  startDateObject.setHours(0, 0, 0, 0);
  setSelectedDate(startDateObject);
}

  // Content for Each Tile
  function tileContent({ date, view }) {
    if (view === "month") {
      const dateStr = toDateString(date);
      const availableTimes = filteredActivityTimes[dateStr];
      let minPrice = availableTimes ? Infinity : 0;

      if (availableTimes) {
        minPrice = availableTimes
          .filter((item) => item.soldOut !== true)
          .reduce((min, curr) => {
            const prices = curr.tickets.map((ticket) => ticket.price);
            const minPriceInCurr = Math.min(...prices);
            return Math.min(min, minPriceInCurr);
          }, Infinity);
      }

      return (
        <div>
          {availableTimes && (
            <p>
              ${minPrice}
              {availableTimes.length > 1 &&
              minPrice !==
                Math.max(
                  ...availableTimes.map((item) =>
                    item.tickets.map((ticket) => ticket.price)
                  )
                )
                ? "+"
                : ""}
            </p>
          )}
        </div>
      );
    }

    return null;
  }

  // OnChange handler when a calendar tile is clicked
  const onChange = (date) => {
    setHighlightedDate(date);
    setSelectedDate(date);
    change("selectedDate", date);
    change("selectedTime", null);
  };

  // Set classes for Tiles as available, selected, or disabled
  const getTitleClass = (date) => {
    const formattedDate = toDateString(date);

    if (Object.keys(filteredActivityTimes).includes(formattedDate)) {
      if (highlightedDate && date.getTime() === highlightedDate.getTime()) {
        return "selected-tile"
      }
      return "is-available";
    }
    return "";
  };

  // Disable tiles on the Calendar
  const tileDisabled = ({ date }) => {
    const dateString = toDateString(date);

    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0); // Set time to midnight to ignore time

    const lastDate = new Date(last(activityDates));
    const afterLastDate = new Date(lastDate);
    afterLastDate.setDate(lastDate.getDate() + 1);
    afterLastDate.setHours(0, 0, 0, 0); // Set time to midnight to ignore time

    return (
      // Disable dates before the current date OR
      // Disable dates after the last day of the data OR
      // Disable any sold out dates OR
      // Disable any dates not included in the data
      date < currentDate ||
      date > afterLastDate ||
      soldOutDates.includes(dateString) ||
      !activityDates.includes(dateString)
    );
  };

  // Initial render to ensure the calculate initial date is selected
  useEffect(() => {
    change("selectedDate", selectedDate);
  }, []);

  return (
    <Calendar
      calendarType="hebrew"
      minDate={new Date()}
      minDetail="month"
      maxDetail="month"
      onClickDay={(date) => onChange(date)}
      next2Label={null}
      prev2Label={null}
      tileClassName={({ date, view }) =>
        view === "month" ? getTitleClass(date) : ""
      }
      tileContent={tileContent}
      tileDisabled={tileDisabled}
      value={selectedDate}
    />
  );
}

BookingCalendar.defaultProps = defaultProps;
BookingCalendar.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    filteredOrderedActivityTimes:
      activitySelectors.filteredOrderedActivityTimes(state),
    orderedActivityTimes: activitySelectors.orderedActivityTimes(state),
    selectedTime:
      formValueSelector("calendar-booking-form")(state, "selectedTime") || "",
  };
}

const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  BookingCalendar
);
