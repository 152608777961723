import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { connect } from "react-redux";
import { selectors as orderSelectors } from "../reducer";
import { selectors as activitySelectors } from "../../activities/reducer";
import { selectors as cartSelectors } from "../../orders/reducer";
import * as apiActions from "api-actions";
import * as Types from "types";
import { ErrorState, LoadingState } from "components";
import Cart from "./Cart";

const propTypes = {
  activities: PropTypes.arrayOf(Types.activity),
  cart: Types.cart,
  order: PropTypes.object,
  fetchActivities: PropTypes.func.isRequired,
  fetchOrder: PropTypes.func.isRequired,
  fetchCart: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
};

const defaultProps = {};

function OrderEdit({
  activities,
  order,
  cart,
  fetchActivities,
  fetchOrder,
  fetchCart,
  params: { id: orderToken },
}) {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchOrder(orderToken, true).catch(() => {
      if (!error) setError("Could not load tickets for this event.");
    });
  }, [orderToken]);

  useEffect(() => {
    if (order) {
      fetchCart(order.cart.token);
      fetchActivities({ viewAll: true });
      setIsLoading(false)
    }
  }, [order])

  if (error) return <ErrorState message={error} />;
  if (!order || isLoading || !cart || !activities) return <LoadingState />;

  return (
    <Cart
      editMode
    />
  )
}

function mapStateToProps(state) {
  return {
    order: orderSelectors.ticketingOrder(state),
    activityTimes: activitySelectors.activityTimes(state),
    activities: activitySelectors.activities(state),
    cart: cartSelectors.cart(state)
  };
}

const mapDispatchToProps = {
  fetchOrder: apiActions.fetchTicketingOrder,
  fetchActivities: apiActions.fetchActivities,
  fetchCart: apiActions.fetchCart
};

OrderEdit.defaultProps = defaultProps;
OrderEdit.propTypes = propTypes;

export default compose(connect(mapStateToProps, mapDispatchToProps))(OrderEdit);
