import { handleActions } from "redux-actions";
import { selectorForSlice, unsetState } from "lp-redux-utils";
import { setOnSuccess, handleSuccess } from "lp-redux-api";
import { createSelector } from "reselect";
import { get, set } from "lodash/fp";
import * as apiActions from "api-actions";
import * as actions from "./actions";
import * as Types from "types";
const reducerKey = "activities";
const slice = "root.activities";

const initialState = {};

const reducer = handleActions(
  {
    [apiActions.fetchActivities]: setOnSuccess("activities"),
    [apiActions.fetchActivity]: setOnSuccess("activity"),
    [apiActions.fetchActivityTimes]: handleSuccess(
      (state, { payload: { data } }) => {
        const isDynamic =
          state.activity.dateSelectionType === Types.DYNAMIC_PRICE_SELECT_TYPE;
        const activityTimes = isDynamic
          ? [...(get("activityTimes", state) || []), ...data]
          : data;
        return set("activityTimes", activityTimes, state);
      }
    ),
    [apiActions.fetchVenues]: setOnSuccess("venues"),
    [apiActions.fetchTickets]: setOnSuccess("tickets"),
    [actions.clearActivity]: unsetState("activity"),
    [actions.clearActivityTimes]: unsetState("activityTimes"),
    [actions.clearTickets]: unsetState("tickets")
  },
  initialState
);

const select = selectorForSlice(slice);

const selectors = {
  activities: select("activities"),
  activity: select("activity"),
  activityTimes: select("activityTimes"),
  tickets: select("tickets"),
  venues: select("venues"),
};

selectors.isTimedActivity = createSelector([selectors.activity], (activity) => {
  if (!activity) return;

  return activity.timed;
});

selectors.isCalendarSelect = createSelector(
  [selectors.activity],
  (activity) => {
    if (!activity) return;

    return activity.dateSelectionType === Types.CALENDAR_DATE_SELECT_TYPE;
  }
);

selectors.isDynamicCalendarSelect = createSelector(
  [selectors.activity],
  (activity) => {
    if (!activity) return;

    return activity.dateSelectionType === Types.DYNAMIC_PRICE_SELECT_TYPE;
  }
);

selectors.orderedActivityTimes = createSelector(
  [selectors.activityTimes],
  (activityTimes) => {
    if (!activityTimes) return;

    const orderedDates = {};

    activityTimes.forEach((item) => {
      const startDate = item.startDate;
      if (!orderedDates[startDate]) {
        orderedDates[startDate] = [];
      }
      orderedDates[startDate].push(item);
    });

    // Make sure activities within each date are sorted by incrementing time
    for (const dateKey in orderedDates) {
      orderedDates[dateKey].sort((a, b) => {
        const startTimeA = new Date(`1970-01-01T${a.startTime}`);
        const startTimeB = new Date(`1970-01-01T${b.startTime}`);
        return startTimeA - startTimeB;
      });
    }

    return orderedDates;
  }
);

selectors.filteredOrderedActivityTimes = createSelector(
  [selectors.orderedActivityTimes],
  (orderedActivityTimes) => {
    if (!orderedActivityTimes) return;

    const soldOutDates = [];
    const filteredActivityTimes = {};

    for (const date in orderedActivityTimes) {
      const activities = orderedActivityTimes[date];
      const allSoldOut = activities.every((activity) => activity.soldOut);

      if (allSoldOut) {
        soldOutDates.push(date);
      } else {
        filteredActivityTimes[date] = activities.filter(
          (activity) => !activity.soldOut
        );
      }
    }

    return { soldOutDates, filteredActivityTimes };
  }
);

export { reducer, selectors, reducerKey };
