import React from "react";
import PropTypes from "prop-types";
import * as Types from "types";
import { compose } from "recompose";
import { lpForm } from "lp-form";
import { Field } from "redux-form";
import { Input, Checkbox, SubmitButton } from "lp-components";
import { noop } from "lodash";
import { Terms, RecaptchaInput } from "components";

const propTypes = {
  skipPaymentDetails: PropTypes.bool,
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  cart: Types.cart.isRequired,
  change: PropTypes.func.isRequired,
  recaptchaSiteKey: PropTypes.string.isRequired,
};

const defaultProps = {
  skipPaymentDetails: false,
  showTermsModal: noop
};

const validateRecaptcha = (value) => {
  // Value will subsequently be validated server-side during order submission
  if (!value) return 'reCAPTCHA must be verified'
}


function BillingForm(
    {
      skipPaymentDetails,
      submitting,
      handleSubmit,
      cart,
      change,
      recaptchaSiteKey
    }) {
  return (
      <form onSubmit={(e, vals) => {
        e.preventDefault()
        change('donationAmount', cart.donationAmount)
        handleSubmit(vals)
      }}>
        <div className="cc-form">
          <h1 className="h4 billing-header">
            {skipPaymentDetails ? "Contact Details" : "Billing Details"}
          </h1>
          <div className="billing-details">
            <div className="billing-form">
              <Field
                name="firstName"
                component={Input}
                className="input-short input-short"
                label={
                  skipPaymentDetails
                      ? "First Name"
                      : "First Name (as shown on card)"
                }
                required
                requiredIndicator="*"
              />
              <Field
                  name="lastName"
                  component={Input}
                  className="input-short input-short-right"
                  label={
                    skipPaymentDetails
                        ? "Last Name"
                        : "Last Name (as shown on card)"
                  }
                  required
                  requiredIndicator="*"
              />
              {!skipPaymentDetails && (
                  <React.Fragment>
                    <Field
                        name="creditCard"
                        component={Input}
                        label="Credit Card Number"
                        required
                        requiredIndicator="*"
                    />
                    <Field
                        name="expMonth"
                        component={Input}
                        className="input-short"
                        label="Expiration Month (MM)"
                        required
                        requiredIndicator="*"
                    />
                    <Field
                        name="expYear"
                        component={Input}
                        className="input-short input-short-right"
                        label="Expiration Year (YYYY)"
                        required
                        requiredIndicator="*"
                    />
                    <Field
                        name="verificationCode"
                        component={Input}
                        className="input-short"
                        label="Security Code"
                        required
                        requiredIndicator="*"
                        aria-describedby="cvv-description"
                    />
                    <p className="input-copy" id="cvv-description">
                      3 to 4 digit number typically located on the back of your card
                    </p>
                  </React.Fragment>
              )}
              <Field
                  name="email"
                  component={Input}
                  label="Email (confirmation will be sent to this address)"
                  required
                  requiredIndicator="*"
              />
              <Field
                  name="phone"
                  component={Input}
                  label="Phone"
                  required
                  requiredIndicator="*"
              />
              <p className="required-field">* denotes a required field</p>
            </div>
          </div>
          <div className="checkbox terms">
            <Field
                name="termsAgreed"
                component={Checkbox}
                labelComponent={Terms}
                required
            />
          </div>
          <Field
              name="recaptcha"
              label={false}
              component={RecaptchaInput}
              recaptchaSiteKey={recaptchaSiteKey}
              validate={validateRecaptcha}
          />
          <hr/>
          <SubmitButton submitting={submitting}>Submit Order</SubmitButton>
        </div>
      </form>
  );
}

BillingForm.propTypes = propTypes;
BillingForm.defaultProps = defaultProps;

export default compose(
    lpForm({
      name: "billing-form",
      constraints: {
        firstName: {
          presence: true,
          length: {maximum: 29},
        },
        lastName: {
          presence: true,
          length: {maximum: 30},
        },
        creditCard: {
          presence: true,
          numericality: {
            onlyInteger: true,
          },
        },
        expMonth: {
          presence: {
            message: "^Expiration month can't be blank",
          },
          numericality: {
            onyInteger: true,
            lessThanOrEqualTo: 12,
            greaterThan: 0,
          },
          length: {
            is: 2,
          },
        },
        expYear: {
          presence: {
            message: "^Expiration year can't be blank",
          },
          numericality: {
            onlyInteger: true,
          },
          length: {
            is: 4,
          },
        },
        verificationCode: {
          presence: {
            message: "^Security code can't be blank",
          },
          numericality: {
            onlyInteger: true,
          },
          length: {
            minimum: 3,
          },
        },
        email: {
          presence: true,
          email: true,
          length: {maximum: 60},
        },
        phone: {
          presence: true,
          length: {maximum: 20},
        },
        termsAgreed: {
          presence: {
            message: "^You must accept the terms and conditions",
          },
          inclusion: {
            within: [true],
            message: "^You must accept the terms and conditions",
          },
        },
      },
      submitOnChange: false,
    })
)(BillingForm);
